import React, {Component} from 'react';

class Projects extends Component{
    render(){
        return(
            <div><h1>Projects Page</h1></div>
        )
    }
}

export default Projects;