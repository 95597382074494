import React, {Component} from 'react';
//import {Grid, Cell} from 'react-mdl';
class about extends Component{
    render(){
        return(
            <div style = {{width: '100%', margin: 'auto'}}>
            <h1>About me</h1>

            </div>
        )
    }
}

export default about;