import React, {Component} from 'react';

class Contact extends Component{
    render(){
        return(
            <div><h1>Contact Page</h1></div>
        )
    }
}

export default Contact;